<template>
  <div class="robot_login">
    <Header :id="6"></Header>
    <Ai100Header :has="1"></Ai100Header>
    <div class="details_box">
      <div class="main_container">
        <div class="login">
          <div class="login_box">
            <div class="box_tit">
              <div :class="tit==1?'tit on':'tit'" @click="titChange(1)">{{$t('lang.login.tit1')}}</div>
              <div :class="tit==2?'tit on':'tit'" @click="titChange(2)">{{$t('lang.login.tit2')}}</div>
            </div>
            <div class="item" v-if="tit==1">
              <el-input v-model="mobile" :placeholder="$t('lang.login.placeholder1')"></el-input>
              <el-input v-model="pwd" :placeholder="$t('lang.login.placeholder2')"></el-input>
              <div class="forget_pass">{{$t('lang.login.fotgetPass')}}</div>
            </div>
            <div class="item item1" v-else>
              <el-input v-model="orderId" :placeholder="$t('lang.login.placeholder3')"></el-input>
            </div>
            <el-button type="primary" @click="SureLogin()" :disabled="disabled">{{$t('lang.login.confirmLogin')}}</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
import Header from '../../components/Header/Header'
import Ai100Header from '../../components/Ai100Header/Index'
// import Footer from '../../components/Footer/Footer'
import { login,loginOrderId } from '../../interface.js'
export default {
  name: 'Login',
  components: {
    Header,
    Ai100Header,
    // Footer,
  },
  data() {
    return {
      mobile: '',
      pwd: '',
      disabled: false,
      tit: 2,
      orderId: ''
    }
  },
  methods: {
    titChange (num) {
      this.tit = num;
    },
    SureLogin () {
      if(this.tit==1){
        this.$alert(this.$t('lang.error.e10043'),this.$t('lang.提示'))
        // const mobile = this.mobile;
        // const pattern = /^1[3456789]\d{9}$/;
        // if(!mobile){
        //   this.$alert('手机号不能为空', '提示', {
        //     confirmButtonText: '确定',
        //     callback: () => {
              
        //     }
        //   })
        //   return false;
        // }
        // if(!pattern.test(mobile)){
        //   this.$alert('手机号格式不正确', '提示', {
        //     confirmButtonText: '确定',
        //     callback: () => {
        //     }
        //   })
        //   return false;
        // }
        // login(this.mobile,res=>{
        //   this.disabled = true;
        //   if(res.code==200){
        //       this.$router.push('/MyToolSystem');
        //       localStorage.mobile = this.mobile;
        
      //        localStorage.loginStyle = 1;
        //       this.disabled = false;
        //   }else{
        //     this.$alert(res.msg, '提示', {
        //       confirmButtonText: '确定',
        //     })
        //     this.disabled = false;
        //   }
        // })
      }else{
        const orderId = this.orderId;
        if(!orderId){          
          this.$message.error(this.$t('lang.login.error6'));
          return false;
        }
        loginOrderId(orderId,res=>{
          console.log(res)
          this.disabled = true;
          if(res.code==200){
              localStorage.orderId = this.orderId;
              localStorage.loginStyle = 2;
              this.$router.push('/RobotSetDetails');
              this.disabled = false;
          }else{
            this.$alert(this.$t('lang.' + res.msg), this.$t('lang.提示'), {
              confirmButtonText: this.$t('lang.确定'),
              type: 'error'
            })
            this.disabled = false;
          }
        })
      }
      
    }
  },
  mounted () {
    if(localStorage.token==""||localStorage.token==null||localStorage.token==undefined){     
        this.$router.push('/');
    }
  }
}
</script>

<style lang="less">
@import '../../assets/styles/varibles.less';
.robot_login{
  .details_box{
    width: 100%;
    min-height: calc(100vh - 210px);
    overflow: hidden;
    margin: auto;
    .el-button--default{
      color: @grey[0];
    }
    .el-button--default:hover{
      border: 1px solid #DCDFE6;
      color: @grey[0];
      background-color: transparent;
    }
    .main_container{
      min-width: @minWidth;
      max-width: @maxWidth;
      margin: auto;
    }
    .login{
      min-height: calc(100vh - 210px);
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      .login_box{
        width: 600px;
        height: 360px;
        margin: 0px auto;
        padding: 25px 34px 34px 34px;
        border: 1px solid #fff;
        box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        .box_tit{
          font-size: @font22;
          color: @fontColor1;
          font-weight: @fontWeight[600];
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 35px;
          .tit{
            font-size: @font16;
            color: rgba(0,0,0,0.6);
            padding-bottom: 6px;
            cursor: pointer;
          }
          .tit.on{
            font-size: @font20;
            color: @fontColor1;
            position: relative;
          }
          .tit.on:before{
            position: absolute;
            display: table;
            content: '';
            bottom: -3px;
            width: 100%;
            height: 3px;
            border-radius: 100px;
            background: linear-gradient(135.23deg, rgba(252,16,184,1) -0.4%,rgba(99,81,226,1) 51.77%,rgba(25,231,215,1) 101.3%);
          }
        }
        .item{
          .item_label{
            display: flex;
            font-size: @font16;
            font-weight: @fontWeight[500];
            color: @fontColor1;
          }
          .el-input{
            display: flex;
            margin-bottom: 20px;
            flex: 1;
            font-size: @font16;
            input{
              height: 48px;
              line-height: 48px;
              border-radius: 6px;
            }
          }
          .el-input:nth-child(2){
            margin-bottom: 0;
          }
          .forget_pass{
            font-size: @font14;
            line-height: 20px;
            color: #1874FF;
            display: flex;
            justify-content: flex-end;
            text-decoration: underline;
            margin-top: 14px;
            margin-bottom: 23px;
          }
        }
        .item1{
          margin: 76px 0 83px 0;
        }
        .el-button{
          width: 100%;
          font-size: @font16;
          height: 55px;
          border-radius: 6px;
        }
      }
    }
  }
}
</style>
